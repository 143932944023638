@import "./scss/style.scss";

.manufacturers {
    &__text {
        margin-top: 80rem;
        @include flex-col;
        gap: 40rem;
        padding-bottom: 80rem;
        position: relative;
        &::after {
            @include grayLineBottom;
        }
    }
    &__pics {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        gap: 20rem;
        & img {
            max-width: 600rem;
            height: auto;
            @include mobile {
                width: 300rem;
            }
        }
    }
    &__list {
        margin: 80rem auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(285rem, 1fr));
        gap: 20rem;
    }
}