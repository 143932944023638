@import "./scss/style.scss";

.footer {
    position: relative;
    &::before {
        @include grayLine;
    }
}
.footer-container {
    @include flex-col;
    gap: 40px;
}
.footer-top {
    margin-top: 28rem;
    margin-inline: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 40rem;
    @include tablet {
        @include flex-col;
        width: 100%;
        margin-inline: auto;
        align-items: center;
        gap: 0;
    }
    &__bloc {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40rem;
        @include tablet {
            display: flex;
            width: 100%;
        }
        @include tablet-small {
            @include flex-col;
            margin-inline: auto;
            gap: 80rem;
        }
    }
    &__logo {
        &>a>img {
            width: auto;
            height: 40rem;
            transition: $transition5;
            @include tablet-small {
                height: 60rem;
            }
            &:active {
                transform: $transY;
            }
        }
    }
    &__title {
        @include text($ff, 500, 16px, 20px, $grayText);
        margin-bottom: 48rem;
    }
    &__text {
        margin-top: 40rem;
        margin-bottom: 20rem;
        @include text($ff, 400, 16px, 20px, $grayText);
        &>a {
            @include text($ff, 400, 16px, 20px, $grayText);
        }
    }
    &__mail {
        @include text($ff, 400, 16px, 20px, $grayText);
    }
    &__socials {
        margin-top: 20rem;
        display: flex;
        gap: 40rem;
    }
    &__links-section {
        display: flex;
        gap: 40rem;
        &__first {
            @include flex-col;
            gap: 40rem;
            &>a {
                @include text($ff, 500, 14px, 20px, $black);
            }
        }
        &__second {
            @include flex-col;
            gap: 40rem;
            &>a {
                @include text($ff, 500, 14px, 20px, $black);
            }
        }
    }
    &__forms {
        @include flex-col;
        width: 40%;
        @include desktop-small {
            width: 30%;
        }
        @include tablet {
            margin-top: 40rem;
            width: 100%;
            position: relative;
            padding-top: 40rem;
            &::before {
                @include grayLine;
            }
        }
    }
    &__inputs {
        & form {
            @include flex-col;
            gap: 20rem;
        }
        & input {
            border-bottom: 1px solid black;
            padding: 0 16px 8px 0;
            @include text($ff, 500, 14px, 20px, $grayText);
            background-color: transparent;
        }
        &__buttons {
            @include flex-between;
            gap: 8rem;
            &>button {
                border-bottom: 1px solid black;
                padding: 8px 0 8px 0;
                @include text($ff, 700, 14px, 20px, $black);
            }
        }
        &__attach {
            display: flex;
            align-items: center;
            gap: 8rem;
            width: 100%;
            & h4 {
                border-bottom: 1px solid black;
                padding: 8px 16px 8px 0;
                @include text($ff, 500, 14px, 20px, $grayText);
            }
        }
    }
}
.footer-copyright {
    display: flex;
    justify-content: space-between;
    position: relative;
    &::before {
        @include grayLine;
    }
    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}
.copyright {
    margin: 40rem 0 12rem;
    @include text($ff, 400, 14px, 20px, $black);
    @include mobile {
        @include text($ff, 400, 10px, 14px, $black);
    }
    & a {
        cursor: pointer;
        @include text($ff, 700, 14px, 20px, $black);
        @include mobile {
            @include text($ff, 700, 10px, 14px, $black);
        }
    }
}

.Message {
    z-index: 50000 !important;
    background-color: $grayLight !important;
    @include text($ff, 700, 14px, 20px, $black);
    border: 1px solid rgba(173, 173, 173, 0.1254901961);
    width: 100%;
}