@import "./scss/style.scss";

.heaven {
    &__container {
        width: 100%;
    }
    &__text {
        margin-top: 80rem;
        @include flex-col;
        gap: 40rem;
        padding-bottom: 80rem;
        position: relative;
        &::after {
            @include grayLineBottom;
        }
    }
    &__cards {
        margin-top: 80rem;
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 40rem;
        margin-bottom: 120rem;
        &>h4 {
            @include text($ff, 500, 30rem, 40rem, $primary);
        }
        @include tablet {
            @include flex-col;
        }
    }
    &__list {
        display: flex;
        gap: 40rem;
        @include tablet-small {
            flex-direction: column;
            width: 100%;
        }
    }
    &__card {
        width: 100%;
        height: 100%;
    }
}