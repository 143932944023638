@font-face {
    font-family: 'PF Agora Sans Pro';
    src: url('../../../public/fonts/PFAgoraSansPro-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'PF Agora Sans Pro';
    src: url('../../../public/fonts/PFAgoraSansPro-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'PF Agora Sans Pro';
    src: url('../../../public/fonts/PFAgoraSansPro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'PF Agora Sans Pro';
    src: url('../../../public/fonts/PFAgoraSansPro-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

$ff: 'PF Agora Sans Pro', sans-serif;

$black: #101010;
$dark: #343336;
$gray: #7d7c7e;
$grayLight: #F4F5F7;
$grayText: #9F9F9F;
$white: #FFFFFF;
$primary: #1919DD;
$secondary: #00ad4f;
$accent: #ff5527;
$accentBlue: #BAF6F7;
$accentGreen: #B9DE6F;
$accentYellow: #F9E89F;

$radiusSmall: 8px;
$radius: 12px;

$shadow: 0 8rem 16rem 0 rgba( 25, 25, 221, 0.1 );

$transition: .2s all ease-in-out;
$transition5: .5s all ease-in-out;

$transY: translateY(15rem);

$desktop: 1365px;
$desktop-small: 1200px;
$tablet: 992px;
$tablet-small: 768px;
$mobile: 576px;
$mobile-small: 370px;