@import "./scss/style.scss";

.two-fr {
  @include flex-col;
  transition: $transition5;
}
.two-fr-detail {
  display: flex;
  flex-direction: row;
  gap: 40rem;
  margin-top: 80rem;
  margin-bottom: 120rem;
  transition: $transition5;
  width: 100%;
  position: relative;
  @include tablet-small {
    flex-direction: column;
    margin-top: 40rem;
    margin-bottom: 80rem;
  }
  &__list {
    @include flex-col;
    gap: 20rem;
    width: 100%;
    transition: $transition5;
    width: 280rem;
    height: auto;
    flex-shrink: 0;
    @include tablet-small {
      flex-direction: row;
      width: 100%;
    }
    & > .two-fr-item {
      cursor: pointer;
      position: relative;
      transition: $transition5;
      @include flex-center;
      width: 100%;
      height: 160rem;
      &.active {
        border-left: 5px solid $primary;
        transition: $transition5;
      }
      &:not(.active) .item-background::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }
      &:hover:not(.active) .item-background::before {
        background: none;
        transition: $transition5;
      }
      .item-content {
        position: absolute;
        z-index: 2;
        text-align: center;
        padding: 20rem;
      }
      .item-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: 0;
      }
      & p {
        @include text($ff, 400, 20px, 26px, $white);
      }
    }
  }
  &__description {
    background-color: $grayLight;
    padding: 20rem 40rem 40rem;
    @include flex-col;
    gap: 20rem;
    width: 100%;
    height: auto;
  }
  &__shorts {
    @include flex-col;
    gap: 12px;
    & h3 {
      @include text($ff, 600, 20rem, 26rem, $black);
      @include tablet-small {
        @include text($ff, 600, 14px, 20px, $black);
      }
      & span {
        @include tablet-small {
          @include text($ff, 600, 14px, 20px, $gray);
        }
        & a {
          cursor: pointer;
          margin: 0 10rem;
          @include tablet-small {
            @include text($ff, 600, 14px, 20px, $gray);
          }
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
  &__socials {
    @include flex-col;
    gap: 12px;
  }
  &__socials-link {
    display: flex;
    align-items: center;
    gap: 12rem;
    & h3 {
      @include text($ff, 600, 20rem, 26rem, $black);
      @include tablet-small {
        @include text($ff, 600, 14px, 20px, $black);
      }
    }
    & a:hover {
      & svg {
        transform: scale(1.5);
      }
    }
  }
  &__paragraph {
    max-width: 900rem;
  }
  &__prices {
    display: flex;
    flex-direction: column;
    gap: 12rem;
    & h3 {
      @include text($ff, 600, 20rem, 26rem, $black);
      @include tablet-small {
        @include text($ff, 600, 14px, 20px, $black);
      }
      & a {
        margin-left: 10rem;
      }
    }
  }
  &__map {
    margin-bottom: 40rem;
  }
  &__photos {
    display: flex;
    width: 100%;
    height: auto;
  }
  &__swiper {
    max-width: 1040rem;
    height: 100%;
  }
  &__slides {
    width: auto;
    height: 500px;
    & > img {
      height: 500px;
    }
  }
}
.swiper-button-prev,
.swiper-button-next {
  transition: $transition5;
  &:active {
    transform: $transY;
  }
}
.swiper-button-prev {
  color: rgba($color: $primary, $alpha: 0.5);
  &:hover {
    color: $primary;
  }
}
.swiper-button-next {
  color: $primary;
}
.two-fr-detail__swiper-list {
  .swiper-list__swiper-top-list {
    width: 100%;
    height: 160px;
  }
  .two-fr-item {
    position: relative;
    cursor: pointer;
    &.active .item-background {
      border-bottom: 5px solid $primary;
      transition: $transition5;
    }
    &:not(.active) .item-background::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 160px;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
    &:hover:not(.active) .item-background::before {
      background: none;
      transition: $transition5;
    }
    .item-content {
      position: relative;
      z-index: 1;
      text-align: center;
      padding: 16px;
      & p {
        @include text($ff, 400, 16px, 20px, $white);
      }
    }
    .item-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 160px;
      background-size: cover;
      background-position: center;
      z-index: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .two-fr-detail {
    &__slides {
      width: auto;
      height: 400px;
      & > img {
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .two-fr-detail {
    &__slides {
      width: auto;
      height: 350px;
      & > img {
        height: 350px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .two-fr-detail {
    &__slides {
      width: auto;
      height: 300px;
      & > img {
        height: 300px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .two-fr-detail {
    &__slides {
      width: auto;
      height: 400px;
      & > img {
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .two-fr-detail {
    &__slides {
      width: auto;
      height: 50vw;
      & > img {
        height: 50vw;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .two-fr-detail {
    &__slides {
      width: auto;
      height: 200px;
      & > img {
        height: 200px;
      }
    }
  }
}
