@import "./scss/style.scss";

.lake-breadcrumbs {
  width: 100%;
  height: auto;
  background: $grayLight;
  margin-top: 80rem;
  &__links {
    display: flex;
    align-items: center;
    gap: 12rem;
    padding: 37rem 20rem;
    & a {
      cursor: pointer;
      transition: 0.3s all;
      @include text($ff, 400, 18rem, 26rem, $grayText);
      &:hover {
        color: $black;
      }
    }
    & h4 {
      @include text($ff, 400, 18rem, 26rem, $primary);
    }
  }
}
.lake {
  @include flex-col;
  &__container {
    @include flex-col;
    gap: 80rem;
    margin-top: 80rem;
    margin-bottom: 120rem;
    width: 100%;
  }
  &__details {
    @include flex-col;
    gap: 80rem;
  }
  &__info {
    @include flex-col;
    gap: 80rem;
  }
  &__short {
    display: flex;
    flex-direction: row;
    gap: 80rem;
    @include tablet {
      flex-direction: column-reverse;
    }
  }
  &__img {
    width: 525px;
    height: 460px;
    @include tablet {
      max-width: fit-content;
      margin-inline: auto;
    }
    @include mobile {
      width: 330px;
      height: 330px;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  &__facts-section {
    @include flex-col;
    position: relative;
    padding-bottom: 40rem;
    &::before {
      @include grayLineBottom;
    }
    & > h1 {
      margin-bottom: 12rem;
    }
  }
  &__short-facts {
    @include flex-col;
    & > h4 {
      @include text($ff, 400, 30rem, 40rem, $grayText);
      margin-bottom: 16rem;
    }
  }
  &__facts-list {
    display: flex;
    gap: 20rem;
    margin-bottom: 40rem;
  }
  &__facts-list-fact {
    @include flex-col;
    gap: 16rem;
    width: 120rem;
    @include tablet {
      max-width: fit-content;
    }
    & h3 {
      @include text($ff, 400, 14px, 18px, $grayText);
      border-right: 1px solid $grayText;
      @include tablet {
        padding-right: 20rem;
      }
    }
  }
  &__facts-list-numbs {
    @include flex-col;
    gap: 16rem;
    & p {
      @include text($ff, 400, 14px, 18px, $grayText);
    }
  }
  &__desc {
    max-width: 600rem;
    margin-bottom: 40rem;
    @include tablet {
      max-width: fit-content;
    }
  }
  &__document {
    @include flex-col;
    gap: 20rem;
  }
  &__photos {
    display: flex;
    width: 100%;
    height: auto;
  }
  &__swiper-first {
    width: 100%;
    height: auto;
  }
  &__swiper-first-slides {
    width: 100%;
    height: auto;
    & img {
      width: 100%;
      height: 500rem;
      @include tablet-small {
        height: 300rem;
      }
    }
  }
  &__other-lakes {
    & h1 {
      margin-bottom: 40rem;
      text-align: center;
    }
  }
}

.video-section {
  max-height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center; 
  video {
    max-height: 100%;
    object-fit: contain;
    border-radius: $radiusSmall;
    box-shadow: $shadow;
    background: $grayLight;
  }
}