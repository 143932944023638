@mixin text($ff, $fw, $fs, $lh, $color) {
    font-family: $ff;
    font-weight: $fw;
    font-size: $fs;
    line-height: $lh;
    color: $color;
}

@mixin desktop {
    @media (max-width: $desktop) {
        @content;
    }
}
@mixin desktop-small {
    @media (max-width: $desktop-small) {
        @content;
    }
}
  
@mixin tablet-small {
    @media (max-width: $tablet-small) {
        @content;
    }
}
  
@mixin tablet {
    @media (max-width: $tablet) {
        @content;
    }
}
  
@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}
@mixin mobile-small {
    @media (max-width: $mobile-small) {
        @content;
    }
}

@mixin flex-center { display: flex; justify-content: center; align-items: center; }
@mixin flex-col { display: flex; flex-direction: column; }
@mixin flex-between { display: flex; justify-content: space-between; align-items: center; }
@mixin flex-around { display: flex; justify-content: space-around; align-items: center; }

@mixin glass-bg {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: $shadow;
    backdrop-filter: blur( 5.5rem );
    -webkit-backdrop-filter: blur( 5.5rem );
}

@mixin title-black {
    background: -webkit-linear-gradient(left, $black, $dark, $gray, $black);
    background-size: 1600px 200px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation-duration: 10s;
    animation-name: animateTitle;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}

@mixin grayLine {
    content: "";
    display: block;
    height: 1px;
    left: 0;
    background: $grayLight;
    top: 0;
    position: absolute;
    width: 100%;
}
@mixin grayLineBottom {
    content: "";
    display: block;
    height: 1px;
    left: 0;
    background: $grayLight;
    bottom: 0;
    position: absolute;
    width: 100%;
}
@mixin leftSideLine {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: 10px;
    background: $primary;
}