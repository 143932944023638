@import "./scss/style.scss";

.home {
  &__top {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 800rem;
  }
  &__top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80rem;
    padding-top: 180rem;
    gap: 20rem;
    @include mobile {
      padding-top: 80rem;
      gap: 0;
    }
    .empty {
      max-width: 600rem;
      height: 100%;
      @include mobile {
        display: none;
      }
    }
  }
  &__txt-container {
    width: auto;
    height: 100%;
    border-radius: $radiusSmall;
    background: url("../../public/bg/1.webp") no-repeat top center;
    background-size: cover;
    padding: 80px;
    box-shadow: $shadow;
    @include tablet {
      padding: 40px;
      margin-top: 40px;
    }
    @include mobile {
      margin-inline: auto;
    }
    & h1 {
      @include text($ff, 700, 52rem, 60rem, $primary);
      margin-bottom: 40rem;
      // color: rgba(0, 0, 0, 0);
      // -webkit-text-stroke-width: 1px;
      // -webkit-text-stroke-color: $primary;
    }
  }
  &__activity {
    margin-top: 80rem;
    & h3 {
      text-align: center;
    }
  }
}
.activity__list {
  margin: 40rem auto 80rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285rem, 1fr));
  gap: 20rem;
  @include desktop-small {
    margin-bottom: 0;
  }
}
.brand {
  background: url("../../public/bg/wave.webp") no-repeat center center;
  background-size: cover;
  width: 100vw;
  height: 1000rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktop {
    width: 100%;
    height: 700rem;
    margin: 120rem 0 120rem 0;
    overflow-x: hidden;
    height: auto;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20rem;
    @include desktop {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__text {
    @include flex-col;
    width: 400rem;
    gap: 12rem;
    & h2 {
      @include text($ff, 700, 36rem, 42rem, $black);
    }
    & p {
      @include text($ff, 400, 18rem, 26rem, $black);
      margin-bottom: 24rem;
    }
  }
  &__slider {
    width: 1020rem;
    @include desktop {
      width: 100%;
    }
  }
}
.brandSwiper {
  & .brandSwiper__txt {
    display: none;
  }
  & .swiper-slide-active {
    width: 100%;
    height: 100%;
    position: relative;
    background: $white;
    & .brandSwiper__txt {
      @include flex-col;
      gap: 12px;
      padding: 40px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $grayLight;
      & h3 {
        @include text($ff, 400, 16rem, 20rem, $grayText);
      }
      & h4 {
        @include text($ff, 700, 28rem, 30rem, $black);
      }
    }
  }
  &__slides {
    width: 400rem !important;
    height: 600rem !important;
  }
  & .swiper-slide-next {
    width: 400rem !important;
    height: 400rem !important;
  }
  & .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & .swiper-pagination-bullet {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #d8d8d8;
      opacity: 1;
    }
    & .swiper-pagination-bullet-active {
      border-radius: 50%;
      width: 28px;
      height: 28px;
      background: $primary;
      border: 8px solid $white;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: $primary;
        z-index: -1;
      }
    }
  }
}
.news {
  margin-bottom: 120rem;
  &__txt {
    text-align: center;
    & h3 {
      margin-bottom: 20rem;
    }
    & p {
      margin-bottom: 80rem;
    }
  }
}

.video-content {
  margin-bottom: 120rem;

  &__txt {
    text-align: center;
    margin-bottom: 40rem;
  }

  &__list {
    max-height: 500px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    video {
      max-height: 100%;
      object-fit: contain;
      border-radius: $radiusSmall;
      box-shadow: $shadow;
      background: $grayLight;
    }
  }
}

.pictures {
  &__txt {
    @include flex-col;
    align-items: center;
    gap: 12rem;
  }
  &__gallery {
    background: $grayLight;
    padding: 40rem 0;
    margin-bottom: 120rem;
    position: relative;
    box-shadow: $shadow;
  }
  .angry-grid {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(16, 1fr);
    gap: 20rem;
    height: 600rem;
  }
  #item-0 {
    background: url("../../public/gallery/5.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 4;
  }
  #item-1 {
    background: url("../../public/gallery/7.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 2;
    grid-column-start: 4;
    grid-row-end: 5;
    grid-column-end: 8;
  }
  #item-2 {
    background: url("../../public/gallery/3.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 5;
    grid-column-start: 2;
    grid-row-end: 9;
    grid-column-end: 5;
  }
  #item-3 {
    background: url("../../public/gallery/4.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 5;
    grid-column-start: 5;
    grid-row-end: 8;
    grid-column-end: 8;
  }
  #item-4 {
    background: url("../../public/gallery/1.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 3;
    grid-column-start: 8;
    grid-row-end: 7;
    grid-column-end: 11;
  }
  #item-5 {
    background: url("../../public/gallery/6.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 2;
    grid-column-start: 11;
    grid-row-end: 6;
    grid-column-end: 14;
  }
  #item-6 {
    background: url("../../public/homePageSlider/2.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 6;
    grid-column-start: 11;
    grid-row-end: 9;
    grid-column-end: 13;
  }
  #item-7 {
    background: url("../../public/gallery/8.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 6;
    grid-column-start: 13;
    grid-row-end: 8;
    grid-column-end: 16;
  }
  #item-8 {
    background: url("../../public/gallery/2.webp") no-repeat center center;
    background-size: cover;
    grid-row-start: 1;
    grid-column-start: 14;
    grid-row-end: 6;
    grid-column-end: 17;
  }
}
.ResponseSwiper {
  width: 800rem !important;
  height: auto !important;
  border-radius: $radiusSmall;
  box-shadow: $shadow;
  @include desktop-small {
    width: 700rem !important;
  }
  @include tablet-small {
    width: 500rem !important;
  }
  @include mobile {
    width: 300rem !important;
  }
}
