@import "./scss/style.scss";

.header {
    position: fixed;
    top: 0;
    width: 100%;
    transition: top 0.3s;
    z-index: 1000;

    &__container {
        @include flex-between;
        gap: 20px;
        padding: 20rem;
        background-color: $white;

        @include mobile {
            padding: 15rem;
        }
    }

    &__logo {
        @include flex-center;
        & img {
            width: auto;
            height: 40rem;
            transition: $transition5;
            @include tablet-small {
                height: 60rem;
            }
            &:active {
                transform: $transY;
            }
        }
    }

    &__nav {
        @include flex-center;

        @include desktop { //////// може tablet-small
            display: none;
        }
    }

    &__ul {
        list-style: none;
        @include flex-between;
        gap: 40rem;
    }

    &__li {
        & a {
            cursor: pointer;
            transition: $transition5;
            @include text($ff, 400, 14rem, 20rem, $primary);
            position: relative;

            &:hover {
                color: $black;
            }

            &.active {
                color: $black;

                &::before {
                    content: '';
                    height: 4px;
                    background: $accent;
                    bottom: -10px;
                    left: 0;
                    z-index: -1;
                    position: absolute;
                    width: 100%;
                }
            }
        }
        &:active {
            transform: $transY;
            transition: $transition5;
        }
    }


    &__burger {
        display: none;
        cursor: pointer;
        position: relative;
        width: 25px;
        height: 18px;
    
        @include desktop { //////// може tablet-small
            display: block;
        }

        .burger-icon,
        .burger-icon::before,
        .burger-icon::after {
            content: '';
            position: absolute;
            width: 25px;
            height: 2px;
            background-color: $primary;
            transition: transform 0.3s, top 0.3s, bottom 0.3s;
        }

        .burger-icon {
            top: 50%;
            transform: translateY(-50%);
        }

        .burger-icon::before {
            top: -6px;
        }

        .burger-icon::after {
            bottom: -6px;
        }

        &.open .burger-icon {
            background-color: transparent; ///////////
        }

        &.open .burger-icon::before {
            top: 0;
            transform: rotate(45deg);
        }

        &.open .burger-icon::after {
            bottom: 0;
            transform: rotate(-45deg);
        }
    }

    &__menu {
        position: absolute;
        // top: 100%;
        right: 0;
        width: 100vw;
        height: auto;
        @include flex-col;
        align-items: center;
        padding: 40rem;
        gap: 20rem;
        transform: translateX(100%);
        transition: $transition5;
        z-index: 99;
        background-color: $white;
        border-top: 1px solid $grayLight;
    
        @include desktop-small { //////// може tablet-small
            display: flex;
        }
        &.open {
          transform: translateX(0);
        }
    }

    &__menu-link {
        margin-bottom: 40rem;

        &>nav>ul>li>a {
            cursor: pointer;
            transition: $transition5;
            @include text($ff, 400, 14px, 20px, $primary);
            position: relative;

            &:hover {
                color: $black;
            }
            &.active {
                color: $black;

                &::before {
                    content: '';
                    height: 4px;
                    background: $accent;
                    bottom: -10px;
                    left: 0;
                    z-index: -1;
                    position: absolute;
                    width: 100%;
                }
            }
        }
    }

    &__menu-ul {
        list-style: none;
        @include flex-col;
        gap: 40rem;
    }
    &__menu-li {
        &:active {
            transform: $transY;
            transition: $transition5;
        }
    }
}