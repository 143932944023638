@import "./scss/style.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  line-height: inherit;
}

html {
  font-size: 1px;
  margin-inline: auto;
  @include desktop-small {
    font-size: 0.9px;
  }
  @include tablet {
    font-size: 0.8px;
  }
  @include tablet-small {
    font-size: 0.7px;
  }
  @include mobile {
    font-size: 0.6px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
body {
  font-family: $ff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 10rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
ul,
li {
  text-decoration: none;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
}
button {
  background-color: transparent;
}
.screen {
  max-width: 1480rem;
  height: auto;
  margin-inline: auto;
  padding: 20rem;
}
.title {
  @include text($ff, 500, 36rem, 42rem, null);
  @include title-black;
}

@keyframes animateTitle {
  0% {
    background-position: 0 1600px;
  }

  100% {
    background-position: 1600px 0;
  }
}

.desc {
  @include text($ff, 400, 20rem, 26rem, $gray);
  @include tablet-small {
    @include text($ff, 400, 14px, 20px, $gray);
  }
}
.mapBtn {
  display: inline-block;
  padding: 12rem 60rem;
  @include text($ff, 500, 20rem, 26rem, $primary);
  text-align: center;
  transition: $transition5;
  cursor: pointer;
  border: 1px solid $primary;
  &:hover {
    background-color: $primary;
    color: $white;
    box-shadow: $primary 0rem 7rem 29rem 0rem;
    &::-moz-selection {
      color: $white;
      background: $primary;
    }
    &::selection {
      color: $white;
      background: $primary;
    }
  }
  &:active {
    transform: $transY;
    box-shadow: $primary 0rem 0rem 0rem 0rem;
    transition: $transition;
  }
}

.cardBtn {
  display: inline-block;
  padding: 12rem 16rem;
  background-color: $white;
  border: 0;
  cursor: pointer;
  transition: $transition5;
  @include text($ff, 400, 16rem, 20rem, $primary);
  &:hover {
    background-color: $primary;
    color: $white;
    box-shadow: $primary 0rem 7rem 29rem 0rem;
    &::-moz-selection {
      color: $white;
      background: $primary;
    }
    &::selection {
      color: $white;
      background: $primary;
    }
  }
  &:active {
    transform: $transY;
    box-shadow: $primary 0rem 0rem 0rem 0rem;
    transition: $transition;
  }
}
.mainBtn {
  display: inline-block;
  padding: 24rem 72rem;
  background-color: $primary;
  border: 0;
  cursor: pointer;
  transition: $transition5;
  @include text($ff, 700, 16rem, 20rem, $white);
  text-transform: uppercase;
  &:hover {
    box-shadow: $primary 0rem 7rem 29rem 0rem;
    &::-moz-selection {
      color: $white;
      background: $primary;
    }
    &::selection {
      color: $white;
      background: $primary;
    }
  }
  &:active {
    transform: $transY;
    box-shadow: $primary 0rem 0rem 0rem 0rem;
    transition: $transition;
  }
  &._small {
    padding: 24rem 32rem;
    @include text($ff, 700, 14rem, 18rem, $white);
  }
}

.LoaderBody {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  animation: 1s dissapear_loader;
  animation-delay: 3s;
}

.loader {
  position: relative;
  animation: 1s dissapear_loader;
  animation-delay: 3s;
}

.loader span {
  position: absolute;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 38px;
  letter-spacing: 5px;
}

.loader span:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 0.3px #2828fb;
}

.loader span:nth-child(2) {
  color: #2828fb;
  -webkit-text-stroke: 1px #2828fb;
  animation: uiverse723 3s ease-in-out infinite;
}

@keyframes uiverse723 {
  0%,
  100% {
    clip-path: polygon(0% 45%, 15% 44%, 32% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
  }

  50% {
    clip-path: polygon(0% 60%, 16% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
  }
}

@keyframes dissapear_loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(500px);
  }
}

.GelleryPhotosPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 50px;

  .prevsPug {
    display: block;
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 8px;
    background-color: #989799;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #2828fb;
      transform: translateY(-3px);
    }
    &:active {
      transform: translateY(3px);
    }
  }

  .GelleryPhotosPaginationButton {
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 8px;
    background-color: #989799;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all;
    .GelleryPhotosPaginationLink {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      transform: translateY(-3px);
    }
  }
  .activePag {
    background-color: #2828fb;
  }
}
