@import "./scss/style.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 7rem;
  background-color: #ffffff00;
}
*::-webkit-scrollbar-thumb {
  border-radius: 7rem;
  background-color: $primary;
}
::-moz-selection {
  color: $accent;
  background: $accentBlue;
}
::selection {
  color: $accent;
  background: $accentBlue;
}

.page {
  &__top {
    margin-top: 80rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.3);
      z-index: 0;
    }
    & img {
      width: 100%;
      height: 350rem;
    }
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include flex-col;
    align-items: center;
    width: 100%;
    gap: 40rem;
    padding: 0 20px;
    & h2 {
      @include text($ff, 700, 48rem, 60rem, $primary);
      text-align: center;
      line-break: auto;
    }
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    gap: 12rem;
    background-color: $white;
    padding: 12px 24px;
    border-radius: $radius;
    & a {
      cursor: pointer;
      @include text($ff, 500, 20rem, 26rem, $gray);
      transition: $transition5;

      &:hover {
        color: $black;
      }
    }
    & h4 {
      @include text($ff, 500, 20rem, 26rem, $black);
    }
  }
}

.pageCard {
  background-color: $grayLight;
  position: relative;
  transition: $transition5;
  box-shadow: $shadow;
  &:hover {
    transform: scale(1.05);
  }
  &__img {
    & img {
      width: 100%;
      height: 300rem;
    }
  }
  &__text {
    @include flex-col;
    gap: 1rem;
    padding: 20rem;
    & p {
      @include text($ff, 500, 14rem, 20rem, $black);
    }
    & h2 {
      @include text($ff, 700, 16rem, 20rem, $black);
      margin-bottom: 20rem;
    }
  }
  &__link {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include glass-bg;
    transition: $transition5;
    height: 100%;
  }
  &:hover > .pageCard__link {
    display: flex;
  }
  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
